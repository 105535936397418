import firebase from "firebase/app";
import Login from "./Login";
import {useEffect, useState} from "react";
import MainApp from "./MainApp";

function App() {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!isSignedIn) {
        return (
            <Login />
        );
    }
    return (
        <MainApp />
    );
}

export default App;
