import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from "firebase/app";
import {useState} from "react";

function Login() {

    const [emailLogin, setEmailLogin] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [emailEntered, setEmailEntered] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<string>("");
    const [register, setRegister] = useState<boolean>(false);
    const [registered, setRegistered] = useState<boolean>(false);
    const [registering, setRegistering] = useState<boolean>(false);


    function loginClicked() {
        setLoginError("");

        if (emailEntered) {
            firebase.auth().signInWithEmailAndPassword(email, password)
                .catch(error => {
                    if (error.code === 'auth/wrong-password') {
                        setLoginError("Chybné jméno nebo heslo.");
                    } else {
                        setLoginError("Chyba: " + error.message);
                    }
                });
        } else {
            setEmailEntered(true);
        }
    }

    function createAccount(email: string, name: string) {
        if (!checkEmail(email)) {
            setLoginError("Chybný formát emailové adresy");
            return;
        }

        setLoginError("");
        setRegistering(true)

        let createAccount = firebase.functions().httpsCallable("createAccount");
        createAccount({
                "email": email,
                "displayName": name,
                "videoId": null,
                "userId": null
            })
            .then(() => {
                setRegistering(false)
                setRegistered(true);
            })
            .catch(error => {
                setRegistering(false);
                setLoginError(error.message);
            })

    }

    function checkEmail(email: string) {
        let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return reg.test(email);
    }

    function signInWithGoogle() {
        alert('Tuto metodu přihlášení zatím nepodporujeme');
        // var provider = new firebase.auth.GoogleAuthProvider();
        // firebase.auth()
        //     .signInWithPopup(provider)
        //     .then(() => {
        //         console.log("logged in");
        //     })
    }

    return <span className="login-one">
        <div className="login-one-box">
            <div className="container">
                <div className="login-one-box-logo">
                    <img className="img-fluid login-one-logo" src="/img/ratingo_logo_v1.png" alt="" />
                </div>
                <div className="login-one-box-container">
                    <form action="#">
                    {register && <>
                        <div className="two-box-container-logo"><img src="/img/2.png" alt="" /></div>
                        <h3 className="two-box-container-heading">Vyzkoušejte Ratingo<br /> zdarma a bez závazků</h3>
                        {!registered && !registering &&
                        <div className="alert alert-danger" role="alert" style={loginError === "" ? {visibility: 'hidden'} : {}}>
                            {loginError}
                        </div>
                        }
                        {registering &&
                        <div className="alert alert-info" role="alert">
                            Probíhá registrace
                        </div>
                        }
                        {registered ?
                            <div className="alert alert-info">
                                Vaše přihlašovací jméno a heslo jsme poslali emailem.
                            </div>
                            :
                            <div className="two-box-input-cover">
                            <input type="text" placeholder="Váš e-mail" name="email" className="form-control two-box-input" disabled={registering} value={email} onChange={e => {setEmail(e.target.value)}} />
                            <input type="text" placeholder="Vaše jméno a příjmení" name="name" className="form-control two-box-input" disabled={registering} value={name} onChange={e => {setName(e.target.value)}} />
                            <img src="/img/Icons-Mail2.png" alt="" />
                            </div>
                        }

                        <div className="two-box-input-btns">
                            {registered ? <>
                                    <a href="#" className="two-login-btn" onClick={e => {e.preventDefault() ; setRegister(false); setRegistered(false);}}>Zpět na přihlášení</a>
                            </>
                                : <>
                                    <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setRegister(false); setEmailEntered(false);}}>Zpět</a>
                                    <a href="#" className="two-login-btn" onClick={e => {e.preventDefault() ; createAccount(email, name);}}>Registrovat</a>
                                </>
                            }

                        </div>
                    </> }
                    { emailLogin && <>
                        <div className="two-box-container-logo"><img src="/img/2.png" alt="" /></div>
                        <h3 className="two-box-container-heading">Přihlásit se</h3>
                        <div className="alert alert-danger" role="alert" style={ loginError === "" ?  {visibility: 'hidden'} : {}}>
                            {loginError}
                        </div>
                        <div className="two-box-input-cover">
                            {emailEntered ? <>
                                <input type="hidden" name="email" value={email} />
                                <input type="password" placeholder="Vaše heslo" name="password" className="form-control two-box-input" value={password} onChange={e => {setPassword(e.target.value)}} />
                                <img src="/img/Icons-Mail2.png" alt="" />
                            </> : <>
                                <input type="text" placeholder="Váš e-mail" name="email" className="form-control two-box-input" value={email} onChange={e => {setEmail(e.target.value)}} />
                                <img src="/img/Icons-Mail2.png" alt="" />
                            </>}

                        </div>
                        <div className="two-box-input-btns">
                            {emailEntered ?
                                <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setLoginError(""); setEmailEntered(false);}}>Zpět</a>
                            :
                                <a href="#" className="two-cancel-btn" onClick={e => {e.preventDefault() ; setLoginError(""); setEmailLogin(false);}}>Zrušit</a>
                            }
                            <a href="#" className="two-login-btn" onClick={e => {e.preventDefault() ; loginClicked();}}>Přihlásit se</a>
                        </div>
                    </> }
                    { !register && !emailLogin && <>
                        <div className="one-box-container-logo"><img src="img/1.png" alt="" /></div>
                        <h3 className="one-box-container-heading">Vytvořte si účet<br /> nebo se přihlašte</h3>
                        <a href="#" onClick={e => { e.preventDefault() ; setRegister(true) }} className="one-box-create-account-btn">

                            <span className="one-google-txt">Vytvořit účet</span>
                        </a>
                        <a href="#" className="one-box-email-btn" onClick={e => {e.preventDefault() ; setEmailLogin(true);}}>

                            <span className="one-email-txt">Přihlásit se</span>
                        </a>
                    </>
                    }
                    </form>
                </div>
            </div>
        </div>
    </span>
}

export default Login;
