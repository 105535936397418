import firebase from "firebase";

function TopBar() {
    function logout() {
        firebase.auth().signOut();
    }

    return <div className="main-header fixed-top">
        <a href="#" className="small-device-header-items">
            <img className="dashboard-logo img-fluid" src="img/ratingo_logo_v1.png" alt="" />
                <input type="checkbox" name="check" className="nav-checker" id="checkbox" />
                    <label htmlFor="checkbox">
                        <svg id="nav-toggler" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path d="M12 3v2H3V3h9zm4 16v2H3v-2h13zm6-8v2H3v-2h19z"/>
                        </svg>
                    </label>
        </a>
        <a href="#" className="header-profile">
            {/*<div className="header-profile-img">*/}
            {/*    <img className="img-fluid" src="/img/Profile-picture.png" alt="" />*/}
            {/*</div>*/}
            <span className="header-profile-name">{firebase.auth().currentUser?.displayName}</span>
            <span className="logout">
                <a href="#" onClick={event => {event.preventDefault(); logout()}}><img src="/img/Logout.svg" alt="" /> </a>
            </span>
        </a>
    </div>
}

export default TopBar;