import Menu from "./includes/Menu";
import TopBar from "./includes/TopBar";
import {useEffect, useState} from "react";
import Videos from "./sections/Videos";
import BuyCredits from "./sections/BuyCredits";
import Settings from "./sections/Settings";
import QrCodes from "./sections/QrCodes";
import VideoGuide from "./sections/VideoGuide";

function MainApp() {
    const [selectedSection, setSelectedSection] = useState("videos");

    useEffect(() => {
        let navChecker: HTMLInputElement | null = document.querySelector('.nav-checker');
        let sideNav = document.getElementById('sidenav');

        navChecker?.addEventListener('change', (event) => {
            if(navChecker?.checked === true) {
                sideNav?.classList.add('content-left-small');
            } else {
                sideNav?.classList.remove('content-left-small');
            }
        });
    });

    return <>
        <main className="main-dashboard">
            <div className="container-fluid">
                <TopBar />
                <div className="main-content">
                    <Menu selectedSection={selectedSection} selectSection={setSelectedSection} />
                    <div className="content-right">
                        {selectedSection === 'videos' && <Videos selectSection={setSelectedSection} />}

                        {selectedSection === 'qrCodes' && <QrCodes selectSection={setSelectedSection} />}

                        {selectedSection === 'videoGuide' && <VideoGuide />}

                        {selectedSection === 'buyCredits' && <BuyCredits />}

                        {selectedSection === 'settings' && <Settings />}
                    </div>
                </div>
            </div>
        </main>
    </>
}

export default MainApp;
